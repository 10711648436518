.sign-container {
  height: 100vh;
  background: url('../images/signBg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.authInput,
select.authInput {
  background-color: transparent !important;
  border: 1.3px solid $primary;
  font-size: large;
  color: white;
  &:focus {
    border: 1.5px solid $primary;
    color: white;
  }
  &::placeholder {
    color: rgb(161, 161, 161);
  }
}

select.authInput {
  font-size: 15px;
}

select.authInput option {
  font-size: 15px;
  color: $dark;
}

select.authInput option:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

textarea {
  height: 120px !important;
  font-size: 15px !important;
  width: 100% !important;
  resize: none !important;
}

.login-button:disabled {
  opacity: 0.9;
}

input:disabled {
  color: $white !important;
  opacity: 0.3 !important;
  background-color: $dark !important;
  border-color: $dark !important;
}

.pwd-container {
  width: 30%;
  margin: auto;
  text-align: center;
  border-radius: 5px;
}

.pwd-wrapper {
  padding-top: 10%;
}

.pwd-wrapper .authInput {
  font-size: 15px;
  color: #ffffff !important;
  height: 45px;
  &::placeholder {
    color: #ffffff !important;
    font-size: 13px;
  }
}
