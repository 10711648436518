.sidebar {
  background-color: $secondary;
  color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.logo-container {
  height: 10%;
  padding-top: 10%;
  text-align: center;
}

.sidebar-logo {
  width: 60%;
}

.sidebar-content {
  height: 80%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.provider {
  height: 35%;
  text-align: center;
}

.provider-initials {
  background-color: #585858;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-menu {
  height: 40%;
  margin-top: 50%;
  text-align: center;
}

.menu-item {
  display: flex;
  align-items: center;
  font-size: medium;
}
