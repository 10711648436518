.loading-container {
  height: 100vh;
  width: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ring {
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5) !important;
}

.ring:before {
  content: ' ';
  position: absolute !important;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid $primary;
  border-right: 3px solid $primary;
  border-radius: 50%;
  animation: animateCircle 2s linear infinite;
  box-sizing: unset !important;
}

.loader-line {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.loader-line:before {
  content: ' ';
  position: absolute;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: $primary;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px $primary;
  box-sizing: unset !important;
}

.load-img {
  margin-top: 40px;
}

@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}
